import React, { useContext, memo } from "react"
import styled from "styled-components"

// Import component
import PdfBlock from "./pdf-block"

// Component definition
const PdfSection = ({ items = [] }) => {
  console.log("pdf section items: ", items)
  return (
    <PdfCarousel>
      {items.map(item => (
        <PdfBlock key={item.id} item={item} />
      ))}
    </PdfCarousel>
  )
}
export default PdfSection

// Styles
const PdfCarousel = styled.div`
  display: flex;
  justify-content: space-between;
`
