import React, { useRef } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

// Import components
import Layout from "../components/layout"
import Seo from "../components/seo"
import PdfSection from "../components/pdf-section"
import TitleBanner from "../components/title-banner"

const AnnouncementsPage = () => {
  const data = useStaticQuery(ANNOUNCEMENTS)
  const pressReleases = data?.allStrapiPressReleases?.nodes || []
  const imageData = data?.allImageSharp?.nodes || []

  const node = useRef()

  return (
    <Layout>
      <Seo title="Home" />
      <TitleBanner imageData={imageData} scrollRef={node}>
        Announcements
      </TitleBanner>
      <PdfSection items={pressReleases} ref={node} />
    </Layout>
  )
}

export default AnnouncementsPage

const ANNOUNCEMENTS = graphql`
  {
    allImageSharp(
      filter: {
        fixed: {
          originalName: {
            in: [
              "banner-image-announcements.png"
              "banner-image-announcements-mobile.png"
            ]
          }
        }
      }
      sort: { fields: fixed___originalName, order: DESC }
    ) {
      nodes {
        gatsbyImageData
      }
    }
    allStrapiPressReleases {
      nodes {
        id
        PDF_File {
          Date
          Title
          File {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`
